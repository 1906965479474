import { createApp } from "vue";
import App from "./app.vue";
import router from "./router";
import "@/assets/css/app.css";
import vTouchEvents from "vue3-touch-events";
import VueYandexMetrika from "vue3-yandex-metrika";

const vueApp = createApp(App);

if (window.VUE_APP_YANDEX_METRIKA_ID) {
  vueApp.use(VueYandexMetrika, {
    id: window.VUE_APP_YANDEX_METRIKA_ID,
    router,
    env: "production",
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true
    }
  });
}

vueApp.use(router);
vueApp.use<{ dragFrequency: number; disableClick: boolean }>(vTouchEvents, {
  dragFrequency: 5,
  disableClick: true
});
vueApp.mount("#app");
