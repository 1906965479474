import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MeetMiddleware from "@/middlewares/meet/meet-middleware";
import HomepageMiddleware from "@/middlewares/homepage/homepage-middleware";
import RedirectMiddleware from "@/middlewares/redirect/redirect-middleware";

const HomepagePage = () =>
  import(/* webpackChunkName: "homepage" */ "@/views/homepage/homepage.vue");
const MeetPage = () =>
  import(/* webpackChunkName: "meet" */ "@/views/meet/meet.vue");

const routes: Array<RouteRecordRaw> = [
  {
    path: "/redirect",
    name: "redirect",
    component: MeetPage,
    beforeEnter: RedirectMiddleware
  },
  {
    name: "index",
    path: "/",
    component: HomepagePage,
    beforeEnter: HomepageMiddleware
  },
  {
    name: "meet",
    path: "/:pathMatch(.*)*",
    component: MeetPage,
    beforeEnter: MeetMiddleware
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
