import MainMiddleware from "@/middlewares/main-middleware";
import AuthMiddleware from "@/middlewares/auth-middleware";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export default async function HomepageMiddleware(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  if (!to.query.errorMessage) {
    await AuthMiddleware(to, from, next);
  } else {
    next();
  }
  MainMiddleware();
}
