import MainMiddleware from "@/middlewares/main-middleware";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import AuthMiddleware from "@/middlewares/auth-middleware";

export default async function MeetMiddleware(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  await AuthMiddleware(to, from, next, false);
  const roomId = to.path.split("/").pop();
  if (!roomId) {
    next({
      name: "index",
      query: {
        errorMessage:
          "Ошибка входа во встречу: передан пустой идентификатор комнаты"
      }
    });
  } else if (to.path.includes("/meet/")) {
    next({
      name: "meet",
      params: {
        pathMatch: roomId
      }
    });
  } else {
    next();
  }
  MainMiddleware();
}
