export class Infrastructure {
  async getMeetUrl(roomId: string) {
    return import("./get-meet-url").then(module => module.default(roomId));
  }

  async getConfig() {
    return import("./config").then(module => module.default());
  }

  async authByCode(code: string) {
    return import("./auth-by-code").then(module => module.default(code));
  }

  async createMeet(roomID: string) {
    return import("./create-meet").then(module => module.default(roomID));
  }

  async refresh() {
    return import("./refresh").then(module => module.default());
  }

  async callQualityReview(payload: {
    roomID: string;
    quality: number;
    comment: string;
    captcha_token: string;
  }) {
    return import("./call-quality-review").then(module =>
      module.default(payload)
    );
  }
}
